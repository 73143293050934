<template>
  <b-card
      no-body
      class="p-1"
  >
    <b-overlay
        :show="state.loading"
        rounded="sm"
    >
      <search-and-filter
          :is-active.sync="isActive"
          :options="columns"
          @filter="getData(1, perPage)"
          @reset="getData(1, perPage)"
      />

      <!--vandar login-->
      <b-modal
          ref="vandar-modal"
          title="ورود به وندار"
          ok-title="تایید پرداخت"
          cancel-title="لغو"
          v-model="vandarModal"
          @ok.prevent="doVandarPaymennt"
      >
        <b-form>
          <b-form-group class="mt-1" label="نام کاربری حساب وندار">
            <b-form-input v-model="vandar.mobile" placeholder="نام کاربری"/>
          </b-form-group>
          <b-form-group class="mt-1" label="رمز عبور حساب وندار">
            <b-form-input v-model="vandar.password" placeholder="رمز عبور" type="password"/>
          </b-form-group>
        </b-form>
      </b-modal>

      <!--toman transaction manual-->
      <b-modal
          ref="toman-modal"
          title="پرداخت دستی"
          ok-title="تایید پرداخت"
          cancel-title="لغو"
          v-model="tomanModal"
          @ok.prevent="successAlert(transactionHash)"
      >
        <b-overlay :show="loading"
                   rounded="sm">
          <b-form label="">
            <p>
              مقدار {{ label[0] }} تومان را به شماره شبای
            </p>
            <p>
              {{ label[1] }}
            </p>
            ارسال نمایید. بعد از انجام تراکنش به صورت دستی شماره پیگیری را در قسمت زیر وارد نمایید و سپس روی
            دکمه
            تایید پرداخت کلیک نمایید
            <b-form-group class="mt-1" label="شماره پیگیری پرداخت">
              <b-form-input v-model="transactionHash" placeholder="شماره پیگیری"/>
            </b-form-group>
          </b-form>
        </b-overlay>
      </b-modal>

      <!--crypto deposit-->
      <b-modal
          ref="crypto-modal"
          title="پرداخت رمزارز"
          ok-title="تایید پرداخت"
          cancel-title="لغو"
          v-model="cryptoModal"
          @ok.prevent="PaymentAlert(selectedWallet.walletName,selectedWallet.totalBalance)"
          @cancel="selectedWallet=''"
      >
        <b-form style="min-height: 250px" @click.stop.prevent="cryptoModal=true">
          <b-form-group label="انتخاب کیف پول">
            <v-select
                v-model="selectedWallet"
                dir="rtl"
                :options="wallets.filter(e => e.relatedCoin === walletUnit)"
                :reduce="val => val.totalBalance<request.amount ? '' : val"
                :clearable="false"
                input-id="user-role"
                label="walletName"
            >
              <template #selected-option="option">
                            <span class="d-flex justify-content-between" style="width: 100%">
                                {{ walletName + ' ' + option.walletName }}
                                <b-badge
                                    dir="ltr"
                                    :variant="option.totalBalance<request.amount?'secondary':'success'">{{ option.totalBalance || 0 + ' ' + $coinUnit[walletUnit] }}
                                </b-badge>
                            </span>
              </template>
              <template #option="option">
                            <span class="d-flex justify-content-between">
                                 {{ option.walletName }}
                                <b-badge
                                    dir="ltr"
                                    class="mr-2"
                                    :variant="option.totalBalance<request.amount?'secondary':'success'">{{ option.totalBalance || 0 + ' ' + $coinUnit[walletUnit] }}
                                </b-badge>
                            </span>
              </template>
            </v-select>
          </b-form-group>
          <transition name="fade">
            <b-form-group label="رمز کیف پول" v-if="selectedWallet">

              <b-input-group class="mb-1" v-tooltip="'حداقل 10 کاراکتر'">
                <b-form-input dir="ltr" class="text-left"
                              v-model="password" trim placeholder="رمز کیف پول"
                              :type="passwordType"/>
                <b-input-group-append is-text>
                  <feather-icon
                      :icon="passwordType==='password'?'EyeIcon':'EyeOffIcon'"
                      class="cursor-pointer"
                      @click="passwordType = passwordType==='password'? 'text':'password'"
                  />
                </b-input-group-append>
              </b-input-group>

            </b-form-group>
          </transition>

          <b-form-group class="mt-1" label="مقدار درخواستی">
            <b-form-input
                type="text"
                :value="request.amount"
                readonly
            />
          </b-form-group>
        </b-form>
      </b-modal>

      <div class="m-1 d-flex justify-content-between"
           dir="ltr"
      >
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="success" @click="downloadExcel">
          <!--                    <feather-icon icon="FilterIcon"/>-->
          <img width="15" src="../../assets/images/excel.png" alt="" class="mr-50"
               style="filter: invert(100%)"/>
          خروجی اکسل درخواست های تومانی
        </b-button>
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" @click="isActive=true">
          فیلتر
          <feather-icon icon="FilterIcon"/>
        </b-button>
      </div>

      <div class="relative-over-x">
        <b-table
            ref="refUserListTable"
            :items="items"
            :small="true"
            responsive
            :fields="columns"
            primary-key="id"
            show-empty
            empty-text="اطلاعاتی یافت نشد"
            @sort-changed="sort($event)"
            no-local-sort
            style="white-space: nowrap; min-height : 235px"
        >
          <template #cell(createdAtDateTime)="data">
                <span dir="ltr">
                    {{ $G2J(data.item.createdAtDateTime) }}
                </span>
          </template>

          <template #cell(amount)="data">
                        <span dir="ltr">
                            {{ $toLocal(data.item.amount) }}
                        </span>
          </template>

          <template #cell(relatedCoin)="data">
            <b-avatar
                size="25"
                class="mr-50"
                :src="require(`@/assets/images/Coins/${data.item.relatedCoin}.png`)"
                variant="light-info"
            />
            {{ $coinLabel[data.item.relatedCoin] }}
          </template>

          <template #cell(action)="{item,index}">
            <template v-if="$havePermission('CREATE_WALLET') || $havePermission('DELETE_ORDERS')">
              <b-dropdown
                  id="dropdown-offset"
                  variant="link"
                  no-caret
                  offset="80px"
                  :right="true"
              >
                <template #button-content>
                  <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item v-if="$havePermission('CREATE_WALLET')"
                                 @click="item.relatedCoin==='TOMAN'?
                                    selectPaymentModal([$toLocal(item.amount,0),item.destinationWalletAddress,item.id])
                                    : PaymentModal($coinLabel[item.relatedCoin],item.relatedCoin,index)"
                >
                  <feather-icon class="text-success" icon="CheckSquareIcon"/>
                  <span class="text-success align-middle ml-50">انجام تراکنش</span>
                </b-dropdown-item>

                <b-dropdown-item v-if="$havePermission('DELETE_ORDERS')"
                                 @click="cancelRequest(index)">
                  <feather-icon class="text-danger" icon="XCircleIcon"/>
                  <span class="text-danger align-middle ml-50">لغو درخواست</span>
                </b-dropdown-item>

                <!-- <b-dropdown-item>-->
                <!--    <feather-icon icon="FileTextIcon"/>-->
                <!--    <span class="align-middle ml-50">جزییات سفارش</span>-->
                <!-- </b-dropdown-item>-->
              </b-dropdown>
            </template>
            <not-allowed v-else/>
          </template>

        </b-table>
      </div>

      <!-- pagination -->
      <div
          class="demo-spacing-0 d-flex justify-content-between m-1"
          dir="rtl"
      >
        <b-pagination
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            align="left"
            @input="getData(currentPage,perPage)"
        >
          <template #prev-text>
            <feather-icon
                icon="ChevronLeftIcon"
                size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
                icon="ChevronRightIcon"
                size="18"
            />
          </template>
        </b-pagination>

        <div>
          <label for="perpage">تعداد در صفحه</label>
          <v-select
              id="perpage"
              v-model="perPage"
              dir="rtl"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              style="min-width: 85px"
              @input="getData(1,perPage)"
          />
        </div>
      </div>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BPagination,
  BCard,
  BButton,
  BTable,
  BDropdown,
  BDropdownItem,
  BBadge,
  BAvatar,
  BForm,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BOverlay,
  // BListGroup,
  // BListGroupItem,
} from 'bootstrap-vue'
import SearchAndFilter from "@/layouts/components/SearchAndFilter";
import vSelect from 'vue-select';
import NotAllowed from "@/layouts/components/NotAllowed";

export default {
  name: 'Withdraw',
  components: {
    NotAllowed,
    SearchAndFilter,
    BPagination,
    BCard,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BBadge,
    BAvatar,
    vSelect,
    BForm,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupAppend,
    BOverlay,
    // BListGroup,
    // BListGroupItem,

  },
  data: () => ({
    test: 0,
    pageLength: 3,
    dir: false,
    searchTerm: '',
    currentPage: 1,
    perPage: 10,
    rows: 20,
    sortBy: '',
    isSortDirDesc: '',
    userData: [],
    label: '',
    transactionHash: '',
    tomanModal: false,
    vandarModal: false,
    cryptoModal: false,
    isActive: false,
    perPageOptions: [5, 10, 20, 50, 100],
    items: [],
    passwordType: 'password',
    vandar: {
      mobile: '',
      password: ''
    },
    columns: [
      {
        label: 'نام کاربر',
        key: 'customer',
        sortable: true,
        searchType: 'text'
      },
      {
        label: 'مقدار درخواستی',
        key: 'amount',
        sortable: true,
        searchType: 'number'
      },
      {
        label: 'واحد درخواست',
        key: 'relatedCoin',
        sortable: true,
        searchType: 'select',
        selectOptions: [
          {label: 'تومان', value: 'TOMAN'},
          {label: 'بیتکوین', value: 'BITCOIN'},
          {label: 'اتریوم', value: 'ETHEREUM'},
          {label: 'بیتکوین کش', value: 'BITCOIN_CASH'},
        ]
      },
      {
        label: 'حساب مقصد',
        key: 'destinationWalletAddress',
        sortable: true,
        searchType: 'text'
      },
      {
        label: 'تاریخ درخواست',
        key: 'createdAtDateTime',
        sortable: true,
        searchType: 'date'
      },
      {
        label: 'عملیات',
        key: 'action'
      },
    ],
    walletName: '',
    walletUnit: '',
    request: '',
    selectedWallet: '',
    walletsList: '',
    password: '',
    loading: false,
    wallets: [
      {name: '', totalBalance: 0},
    ],
  }),
  computed: {
    statusVariant() {
      const a = {
        inactive: 'danger',
        active: 'success',
      }
      return e => a[e];
    },
    statusLabel() {
      const a = {
        inactive: 'لغو شده',
        active: 'انجام شده',
      }
      return e => a[e];
    },
  },
  methods: {
    selectPaymentModal(e) {
      console.log(e)
      this.label = e
      this.boxTwo = ''
      this.$bvModal
          .msgBoxConfirm('لطفا طریقه انجام تراکنش را انتخاب کنید.', {
            title: 'شیوه پرداخت',
            size: 'sm',
            okVariant: 'primary',
            okTitle: 'درگاه بانکی',
            cancelTitle: 'پرداخت دستی',
            cancelVariant: 'outline-secondary',
            hideHeaderClose: false,
            centered: true,
          })
          .then(value => {
            if (value) {
              this.doVandarPaymennt()
            } else if (value === false) {
              this.tomanModal = true
            }
          })
    },

    async successAlert(e) {
      if (!this.loading) {
        this.loading = true
        await this.$axios.post('/wallets/manual-defray/' + this.label[2], {trackCode: this.transactionHash})
        this.$swal({
          icon: 'success',
          title: `تراکنش با شماره پیگیری ${e} ثبت شد`,
          confirmButtonText: 'تایید',
          customClass: {
            confirmButton: 'btn btn-success',
          },
        })
        this.loading = false
        this.tomanModal = false
        this.getData()
      }
    },

    async doVandarPaymennt() {
      try {
        this.state.loading = true
        await this.$axios.post('/wallets/defray/' + this.label[2], this.vandar)
        this.$error('پرداخت با موفقیت انجام شد', '', 'success')
        this.getData(this.currentPage, this.perPage)
      } catch (e) {
        if (e.response.data.message.includes('Unauthorized Error')) {
          this.$error('نیازمند به ورود',)
          this.vandarModal = true
        } else {
          const err = JSON.parse(e.response.data.errors[0].match(/\[{(.*?)\}]/)[0])[0]
          this.$error(err.error, err.errors.amount)
        }
      }
    },

    PaymentAlert(e, a) {
      this.label = e
      this.$swal({
        title: 'موجودی کیف پول ' + this.walletName + ' ' + e + ' : ' + this.$toLocal(a),
        text: "آیا از انجام تراکنش مطمئن هستید؟",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'انجام تراکنش',
        cancelButtonText: 'خیر',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(async (result) => {
        if (result.value) {
          this.state.loading = true
          const address = ['STELLAR', 'RIPPLE'].includes(this.walletUnit) ? '/wallets/account-transaction-for-withdrawal-request' : '/wallets/hd/transaction-for-withdrawal-request'
          await this.$axios.post(address, {
            walletName: this.selectedWallet.walletName,
            id: this.selectedWallet.id,
            withdrawalRequestId: this.request.id,
            password: this.password,
          })

          this.cryptoModal = false

          await this.getData(this.currentPage, this.perPage)

          this.$swal({
            icon: 'success',
            title: 'تراکنش انجام شد',
            confirmButtonText: 'تایید',
            // text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },

    async downloadExcel() {
      await this.$axios(
          {
            url: '/wallets/toman-withdrawal-excel',
            method: 'GET',
            responseType: 'blob', // important
            params: {
              fileName: this.$jmoment().format(this.$dateFormat['faDateTime']),
              page: this.currentPage,
              size: this.perPage,
              ...this.$route.query
            }
          }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', this.$jmoment().format(this.$dateFormat['faDateTime']) + '.xls'); //or any other extension
        document.body.appendChild(link);
        link.click();
      })
    },

    async PaymentModal(l, u, i) {
      this.state.loading = true
      this.walletName = l
      this.walletUnit = u

      const res = await this.$axios('/wallets?relatedCoin=' + u)

      this.wallets = res.data.content

      this.request = this.items[i]

      this.cryptoModal = true
    },

    cancelRequest(e) {
      const a = this.items[e]
      this.$swal({
        icon: 'error',
        title: "آیا از لغو درخواست کاربر مطمئن هستید؟",
        confirmButtonText: 'تایید و لغو درخواست',
        showCancelButton: true,
        cancelButtonText: 'خیر',
        text: "مشخصات درخواست : کاربر " + a.customer + " مقدار " + this.$toLocal(a.amount) + " " + this.$coinLabel[a.relatedCoin],
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-primary ml-1',
        },
      }).then(async (result) => {
        if (result.value) {
          this.state.loading = true
          const address = '/wallets/withdrawal-request/cancel/' + this.items[e].id
          const res = await this.$axios.post(address)

          console.log(res.data.baseDTO)

          await this.getData(this.currentPage, this.perPage)

          this.$swal({
            icon: 'success',
            title: 'سفارش لغو شد',
            confirmButtonText: 'تایید',
            // text: 'Your file has been deleted.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      })
    },

    sort(e) {
      console.log(e)

      let sort = this.$toSnakeCase(e.sortBy)
      // let sort = e.sortBy
      let sorting = e.sortDesc ? 'DESC' : 'ASC'

      this.$router.push({
        query: {
          ...this.$route.query,
          orderBy: sort,
          sorting: sorting
        }
      })

      this.getData(1, this.perPage)

    },

    async getData(page = 1, perPage = this.perPage) {
      this.state.loading = true

      const queryParams = {
        size: perPage,
        page: page,
        ...this.$route.query
      }
      const res = await this.$axios('/wallets/withdrawal-requests?withdrawalRequestStatus=RAISED_BY_USER', {params: queryParams})

      this.state.loading = false
      this.items = res.data.content
      this.currentPage = res.data.number + 1
      this.rows = res.data.totalElements
    },
  },
  created() {
    this.getData()
  }
}
</script>
<style lang="scss">
.vs__selected {
  width: calc(100% - 20px);
}

[v-cloak] {
  opacity: 0;
}

.nowrap {
  white-space: nowrap;
}

[dir="rtl"] .test-badge {
  font-size: 10px;
  top: -6px;
  left: -5px !important;
  min-width: 16px;
  min-height: 16px;
}
</style>
